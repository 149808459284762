<template>
  <section class="py-12 bg-white overflow-hidden md:py-20 lg:py-24" v-if="quote">
    <div class="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="relative">
        <img alt="Team Logo" :src="quote.logo" class="mx-auto h-16 w-16 rounded-full" v-if="quote.logo">
        <blockquote class="mt-10">
          <div class="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-900">
            <p>&ldquo;{{ quote.text }}&rdquo;</p>
          </div>
          <footer class="mt-8">
            <div class="md:flex md:items-center md:justify-center">
              <div class="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                <div class="text-base font-medium text-gray-900">{{ quote.author }}</div>
                <svg class="hidden md:block mx-1 h-5 w-5 text-wiq-green" fill="currentColor" viewBox="0 0 20 20" v-if="quote.authorTitle">
                  <path d="M11 0h3L9 20H6l5-20z" />
                </svg>
                <div class="text-base font-medium text-gray-500" v-if="quote.authorTitle">{{ quote.authorTitle }}</div>
              </div>
            </div>
          </footer>
        </blockquote>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'feature-quote',
  props: {
    showQuoteId: {
      type: Number,
      required: false,
    }
  },
  data() {
    return {
      quote: null,
      quotes: {
        0: {
          text: 'With WrestlingIQ I spend about half the time I used to organizing, labeling, and emailing wrestlers when their videos are ready. That’s time I can now use to promote the team and work with wrestlers.',
          logo: 'https://mktg-cdn.wrestlingiq.com/logos/customers/bixby_200x200.png',
          author: 'Ray Blake',
          authorTitle: 'Head Coach, Bixby HS',
        },
        1: {
          text: 'No more having one system for a website, another for emails and another for registration fees. Matt has done an amazing job creating a great tool that is always getting better and better. If you haven’t already make the switch to WrestlingIQ!',
          logo: 'https://mktg-cdn.wrestlingiq.com/logos/customers/lswc_200x200.png',
          author: 'Andy Knutson',
          authorTitle: 'Coach & Office Manager, Lake Stevens Wrestling Club'
        },
        2: {
          text: 'WrestlingIQ went so well this past season that next year we will be having video review days with the entire team. The wrestlers are taking responsibility for their learning and are engaged with note taking. WrestlingIQ has permanently changed how we approach video review.',
          logo: null,
          author: 'Sean Gullikson',
          authorTitle: 'Head Coach, Skyline HS',
        },
        3: {
          text: 'With WIQ you will grow your wrestling team with a focus on coaching and spending less time on admin work.',
          logo: 'https://mktg-cdn.wrestlingiq.com/logos/customers/jeff_200x200.png',
          author: 'Jeff Marsh',
          authorTitle: 'Founder & Head Coach, The Mat Club'
        },
        4: {
          text: 'WrestlingIQ has taken the management of our wrestling club from a several hour a week job to a couple of hours a month. The efficiency in which the program operates for the cost is remarkable. Having the ability to communicate, through messaging, stream registration and payments, as well as get USAW card information and get liability forms signed is convenient and user friendly. WrestlingIQ has been a game changer for our club.',
          logo: 'https://mktg-cdn.wrestlingiq.com/logos/customers/black_sheep_200x200.png',
          author: 'Daniel McCune',
          authorTitle: 'Beat the Streets Bay Area, Sunnyvale Wrestling Club, Fremont HS'
        },
        5: {
          text: 'WrestlingIQ has been great for the Beaver Dam RTC for so many reasons. It makes our members easy to navigate and donors as well.',
          logo: 'https://mktg-cdn.wrestlingiq.com/logos/customers/damrtc_200x200.png',
          author: 'Nate Engel',
          authorTitle: 'Beaver Dam RTC Coach, Associate Head Coach Oregon State University'
        },
        6: {
          text: 'The WrestlingIQ platform streamlines paperwork and communication, so coaches can spend more of their time doing what they love: coaching.',
          logo: null,
          author: 'Greg Nelson',
          authorTitle: 'Coach, Wrestling Club Tacoma Slam'
        },
        7: {
          text: 'WrestlingIQ is simple and efficient. We\'ve worked with a number of different software systems and WrestlingIQ is by far the best. Matt does a phenomenal job and makes himself accessible to us for whatever needs we have. We couldn\'t be more pleased with our move to WrestlingIQ. I highly recommend it to any coach who has a program or is looking to start one.',
          logo: 'https://mktg-cdn.wrestlingiq.com/logos/customers/yale_st_200x200.jpg',
          author: 'Donnie DeFilippis',
          authorTitle: 'Founder & Head Coach, Yale St. Wrestling Club'
        },
        8: {
          text: 'WrestlingIQ minimizes the admin work required to run a wrestling program and allows us coaches to spend our time on the mat – where we want to be. I recommend WrestlingIQ to any coach who is looking to take their club to the next level.',
          logo: 'https://mktg-cdn.wrestlingiq.com/logos/customers/cwc_200x200.png',
          author: 'Chris Caldwell',
          authorTitle: 'Executive Director of the CA RTC, Coach at CWC',
        },
        9: {
          text: 'Our new website with WrestlingIQ made an immediate impact and allowed us to reach a broader audience. The all-in-one package of front-end and behind-the-scenes tools allow us to efficiently manage everything from registrations to communication to fundraising. You won\'t find a more user-friendly team than WrestlingIQ!',
          logo: 'https://mktg-cdn.wrestlingiq.com/logos/customers/wpwc_200x200.png',
          author: 'West Point Wrestling Club and RTC',
          authorTitle: null,
        },
        10: {
          text: 'Made by a wrestler for wrestlers. WrestlingIQ has made managing our members a whole lot easier. I am grateful for Matt and his willingness to make adjustments based on requests and feedback. There isn\'t another CRM company out there (especially for wrestling clubs) that is this personal and receptive to feedback.',
          logo: 'https://mktg-cdn.wrestlingiq.com/logos/customers/edge_hoboken_200x200.png',
          author: 'Elmer Fajardo',
          authorTitle: 'Edge Hoboken'
        },
        11: {
          text: 'As a one-stop shop for our practice and competition calendars, videos, messaging, fundraisers, booster club payments, and more, WrestlingIQ has proven to be extremely valuable in keeping the 200+ wrestlers, parents, and coaches in our program aligned and moving forward in the same direction.',
          logo: 'https://mktg-cdn.wrestlingiq.com/logos/customers/bixby_200x200.png',
          author: 'Ray Blake',
          authorTitle: 'Head Coach, Bixby HS'
        },
        12: {
          text: 'WrestlingIQ has been a great platform for us to use at Compound Wrestling Club. Matt has everything streamlined and easy to use and is always quick to respond with any questions we have. 100% would recommend using it for your club!',
          logo: 'https://mktg-cdn.wrestlingiq.com/logos/customers/cmp_200x200.jpg',
          author: 'Josh Nolan',
          authorTitle: 'Head Coach, Compound Wrestling Club'
        },
        13: {
          text: 'As head of mentoring and coaching for Beat the Streets Cleveland, having a software program that can manage over a hundred kids and twenty coaches is paramount in our success with communication and data collection. WrestlingIQ has been an asset to our procedures, routines, and ability to implement training at all levels. Remarkably, every problem we have ever had with Wrestling IQ has been addressed and updated promptly. Currently, the software is better than ever and functions for all of our program’s needs. I am thoroughly impressed by the WIQ support staff for their attention to the detail and knowledge of what is required to run an effective wrestling program.',
          logo: 'https://mktg-cdn.wrestlingiq.com/logos/customers/btscle_200x200.png',
          author: 'Anthony Spooner',
          authorTitle: 'Beat the Streets Cleveland'
        }
      }
    }
  },
  mounted() {
    let index = this.showQuoteId ? this.showQuoteId :  _.random(0, Object.keys(this.quotes).length - 1);
    this.quote = this.quotes[index]
  }
};
</script>
<style scoped>
</style>
