<template>
  <div class="px-4 lg:px-0 lg:grid lg:grid-cols-2 lg:gap-12">
    <div class="lg:py-24">
      <p class="text-gray-500 text-sm uppercase tracking-wide">{{ subhead }}</p>
      <h2 :class="titleClassOverride ? titleClassOverride : 'mt-1 text-xl tracking-tight font-bold text-gray-700 sm:text-2xl'">
        {{title }}
      </h2>
      <p class="mt-2 text-base text-gray-700 sm:mt-5 sm:text-lg">
        {{ description }}
      </p>
      <div class="text-gray-700 mt-6 grid gap-x-6 gap-y-8 lg:grid-cols-2 lg:mt-12">
        <div v-for="benefit in benefits" :key="benefit.id"
             class="cursor-pointer"
             @mouseover="selectedBenefitId = benefit.id"
             @click="selectedBenefitId = benefit.id"
        >
          <h3 class="text-base font-semibold leading-5" :class="selectedBenefitId === benefit.id ? 'text-wiq-green' : 'text-gray-700'">
            {{ benefit.h3 }}
          </h3>
          <p class="mt-2">
            {{ benefit.p }}
          </p>
        </div>
      </div>
    </div>
    <div class="relative mt-12 pb-12 lg:mt-0">
      <div class="relative aspect-h-2 aspect-w-3">
        <img :src="selectedBenefit.image" class="rounded-md drop-shadow-lg-wiq-green object-cover border border-gray-200"/>
      </div>
      <div class="relative mt-12 w-full grid grid-cols-2 gap-4 lg:grid-cols-4 lg:gap-4 lg:place-self-center items-center">
        <img v-for="benefit in benefits" :key="benefit.id"
             :src="benefit.image"
             class="object-cover h-36 w-full object-cover rounded-sm hover:cursor-pointer lg:h-32 lg:w-32"
             :class="selectedBenefitId === benefit.id ? 'ring-blue-200 ring' : ''"
             @mouseover="selectedBenefitId = benefit.id"
             @click="selectedBenefitId = benefit.id"
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'benefits-carousel',
  props: {
    subhead: {
      type: String,
    },
    title: {
      type: String,
      required: true,
    },
    titleClassOverride: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      required: true,
    },
    // We expect benefits to come in the form like so
    // :benefits="[{id: 1, h3: '', p: '', image: ''}]"
    benefits: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      selectedBenefitId: null,
    }
  },
  computed: {
    selectedBenefit() {
      return _.find(this.benefits, ['id', this.selectedBenefitId]);
    }
  },
  created() {
    this.selectedBenefitId = this.benefits[0].id;
  }
};
</script>
<style scoped>
</style>
