<template>
  <div v-show="usingV2Captcha && !forceHide" id="v2_captcha">
  </div>
</template>
<script>
// We load up the V3 recaptcha and render a v2
// v2 remains hidden until server tells us the user failed v3
// This is not scoped into the component, because it needs to be a global function
function loadRecaptcha(v3Key, callback) {
  if (window && !window.grecaptcha) {
    let recaptchaScript = document.createElement('script')
    document.head.appendChild(recaptchaScript)
    recaptchaScript.onload = () => {
      window.grecaptcha.ready(() => {
        callback()
      })
    }
    // https://developers.google.com/recaptcha/docs/faq for running both v3 and v2 on the same page
    recaptchaScript.setAttribute('src', `https://www.google.com/recaptcha/api.js?render=${v3Key}`)
  } else {
    callback()
  }
}

export default {
  name: 'recaptcha',
  props: {
    captchaAction: {
      type: String,
      required: true,
    },
    captchaSiteKey: {
      type: String,
      required: true,
    },
    captchaV3SiteKey: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      captchaVersion: 'v3',
      forceHide: false,
    }
  },
  mounted() {
    let vm = this;
    loadRecaptcha( vm.captchaV3SiteKey, function() {
      window.grecaptcha.render('v2_captcha', {
        'sitekey' : vm.captchaSiteKey
      });
    });
  },
  computed: {
    usingV2Captcha() {
      return this.captchaVersion === 'v2';
    },
  },
  methods: {
    forceHideCaptcha() {
      this.forceHide = true;
    },
    handleCaptchaFailed() {
      if (this.usingV2Captcha) {
        // If we are already using v2, reset it so they can try again
        window.grecaptcha.reset('v2_captcha');
      } else {
        // Switch from v3 to explicit v2
        this.captchaVersion = 'v2';
      }
    },
    async getCaptchaResponse() {
      let captchaResponse = 'unknown';
      if (this.usingV2Captcha) {
        captchaResponse = window.grecaptcha.getResponse();
      } else {
        captchaResponse = await window.grecaptcha.execute(this.captchaV3SiteKey, { action: this.captchaAction }).catch((err) => 'invalid-recaptcha');
      }

      return captchaResponse;
    }
  },
}

</script>
