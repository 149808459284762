<template>
  <div class="lg:grid lg:grid-cols-5 lg:gap-8">
    <div class="max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-0 lg:text-left lg:flex lg:col-span-2">
      <div class="lg:py-24">
        <h1 class="mt-4 text-4xl tracking-tight font-extrabold text-gray-700 sm:mt-5 sm:text-6xl lg:mt-6 lg:pr-8">
          <span v-show="showGrid">Pricing</span>
          <span v-show="!showGrid">{{ viewing }}<br class="hidden lg:block"
                                                    v-if="viewing.indexOf(' ') >= 0"> Pricing</span>
        </h1>
        <p class="mt-3 text-base text-gray-700 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
          Tap your team level for pricing details.<br>Questions? <a href="/contact" class="underline cursor-pointer">Get in touch</a>
        </p>
        <div class="mt-12" v-if="viewing === 'Club'">
            <div class="flex flex-col space-y-1">
                <span class="w-fit inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">Paid add-on (optional)</span>
                <h3 class="text-xl font-bold tracking-tight text-gray-900">
                    Premium website services help you get discovered
                </h3>
            </div>
            <div>
                <p class="mt-2 text-base underline text-wiq-green cursor-pointer" @click="showWebsiteDetails = true" v-if="!showWebsiteDetails">
                    Learn more >
                </p>
                <div v-if="showWebsiteDetails">
                    <p class="mt-2 text-lg text-gray-600">
                        Know you need a good looking website, but don't want to deal with it? WrestlingIQ's premium website services
                        give you a custom website without the DIY hassle. Need a change to the website? Just send over an email.
                    </p>
                    <p class="mt-2 text-base text-gray-600">
                        <span class="font-bold">Pricing.</span> $500 upfront for design + coding. $49/month for hosting, domain registration, and website updates.
                    </p>
                    <p class="mt-2 text-base text-gray-600">
                        <span class="font-bold">Who's it good for?</span> Wrestling clubs looking to grow their membership through local SEO. Typically we aim for a clean and clear 5-6 page website.
                    </p>
                    <p class="mt-2 text-base text-gray-600">
                        <span class="font-bold">Who is it not for?</span> If you need a DIY editor or plan on operating a blog about the club, Squarespace or Wordpress is going to be a better fit. <a href="https://www.daniellehobeika.com/" target="_blank" class="underline">Danielle Hobeika</a> is a wrestler and excellent Wordpress consultant if you want to explore that.
                    </p>
                    <p class="mt-2 text-base text-gray-600">
                        <span class="font-bold">Examples</span>. <a href="https://www.yalestreetwc.com/" target="_blank" class="underline text-ys-red">Yale St
                        Wrestling Club</a>,
                        <a href="https://www.thedamrtc.org" target="_blank" class="underline text-dam-orange">The Dam RTC</a>,
                        <a href="https://www.westpointwrestlingclub.org/" target="_blank" class="underline text-wp-gray">West
                            Point® Wrestling Club</a>, <a href="https://www.clublucha.com/" target="_blank" class="underline text-lucha-purple">Club Lucha</a>,
                        <a href="https://www.ochorioswrestlingclub.com/" target="_blank" class="underline text-emerald-700">Ocho Rios Wrestling Club</a>,
                        <a href="https://wrestlingfactory.georgeschool.org/" target="_blank" class="underline text-gs-green">Wrestling Factory at George School</a>.
                    </p>
                </div>
            </div>
        </div>
      </div>
    </div>
    <div class="mt-12 px-4 lg:mt-24 lg:max-w-3xl lg:px-0 lg:col-span-3">
      <div v-show="showGrid" class="grid grid-cols-1 gap-4 md:gap-8 md:grid-cols-2 lg:grid-cols-3">
        <div class="pricing-chip" @click="viewing = 'Club'">
          <h2>
            Club
          </h2>
        </div>
        <div class="pricing-chip" @click="viewing='High School'">
          <h2>
            High School
          </h2>
        </div>
        <div class="pricing-chip" @click="viewing = 'College'">
          <h2>
            College
          </h2>
        </div>
        <div class="pricing-chip" @click="viewing = 'RTC'">
          <h2>
            RTC
          </h2>
        </div>
        <div class="pricing-chip" @click="viewing='Booster Club'">
          <h2>
            Booster Club
          </h2>
        </div>
        <div class="pricing-chip" @click="viewing='Middle School'">
          <h2>
            Middle School
          </h2>
        </div>
      </div>
      <div v-if="!showGrid" @click="goBack()" class="flex items-center mb-8">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
             stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7"/>
        </svg>
        <p class="underline text-gray-900 cursor-pointer">
          Back
        </p>
      </div>
      <div v-if="viewing === 'Booster Club'">
        <booster-pricing></booster-pricing>
      </div>
      <div v-if="viewing === 'College'">
        <college-pricing></college-pricing>
      </div>
      <div v-if="viewing === 'Club'">
        <club-pricing></club-pricing>
      </div>
      <div v-if="viewing === 'High School'">
        <highschool-pricing></highschool-pricing>
      </div>
      <div v-if="viewing === 'RTC'">
        <rtc-pricing></rtc-pricing>
      </div>
      <div v-if="viewing === 'Middle School'">
        <middleschool-pricing></middleschool-pricing>
      </div>
    </div>
  </div>
</template>
<script>
import BoosterPricing from './booster-pricing.vue';
import ClubPricing from './club-pricing.vue';
import CollegePricing from './college-pricing.vue';
import HighschoolPricing from './highschool-pricing.vue';
import MiddleschoolPricing from './middleschool-pricing.vue';
import RtcPricing from './rtc-pricing.vue';

export default {
  name: 'pricing-grid',
  components: {
    BoosterPricing,
    ClubPricing,
    CollegePricing,
    HighschoolPricing,
    MiddleschoolPricing,
    RtcPricing,
  },
  props: {
    teamType: {
      type: String,
      required: false,
    }
  },
  data: function () {
    return {
      viewing: 'grid',
      firedFromPopState: false,
      showWebsiteDetails: false,
    };
  },
  mounted() {
    let vm = this;
    window.addEventListener("popstate", function(event) {
      console.log(event);
      vm.firedFromPopState = true;
      if (event.state?.viewing) {
        vm.viewing = event.state.viewing;
      } else {
        vm.viewing = 'grid';
      }
    });
    switch (this.teamType) {
      case 'Club':
      case 'College':
        this.viewing = this.teamType;
        break;
      case 'High school':
        this.viewing = 'High School';
        break;
      case 'Booster club':
        this.viewing = 'Booster Club';
        break;
      case 'RTC':
        this.viewing = 'RTC';
        break;
      case 'Middle school / Junior high':
        this.viewing = 'Middle School';
        break;
      default:
        break;
    }
  },
  methods: {
    goBack() {
      history.back()
    }
  },
  computed: {
    showGrid() {
      return this.viewing === 'grid';
    },
  },
  watch: {
    viewing(newViewing) {
      if (newViewing !== 'grid' && !this.firedFromPopState) {
        const params = new URLSearchParams({
          team_type: this.viewing,
        });
        console.log('pushing state');
        history.pushState({ viewing: newViewing }, null, '/pricing');
      }

      this.firedFromPopState = false;
    }
  }
};
</script>
