<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <template v-if="sent">
      <slot name="sent">
        <div class="rounded-md p-4">
          <p class="text-bg-gray-700 text-lg text-center mt-12">
            Thank you, your message has been sent. I'll be in touch shortly.
          </p>
        </div>
      </slot>
    </template>
    <div class="grid grid-cols-1 gap-y-6" role="form" v-if="!sent">
      <div>
        <label for="name" class="text-gray-700 block text-sm font-medium leading-5">Name</label>
        <ValidationProvider name="name" rules="required" v-slot="{ errors }">
          <div class="mt-1 relative rounded-md shadow-sm">
            <input v-model="name" id="name" :class="inputClasses(errors[0], '')" type="text">
            <error-icon v-if="errors[0]"></error-icon>
          </div>
          <p class="mt-2 text-sm text-red-600" v-if="errors[0]">
            {{ errors[0] }}
          </p>
        </ValidationProvider>
      </div>
      <div>
        <label for="email" class="text-gray-700 block text-sm font-medium leading-5">Email</label>
        <ValidationProvider name="email" rules="required|email" :debounce="500" v-slot="{ errors }">
          <div class="mt-1 relative rounded-md shadow-sm">
            <input v-model="email" id="email" :class="inputClasses(errors[0], '')" type="email">
            <error-icon v-if="errors[0]"></error-icon>
          </div>
          <p class="mt-2 text-sm text-red-600" v-if="errors[0]">
            {{ errors[0] }}
          </p>
        </ValidationProvider>
      </div>
      <div>
        <label for="message" class="text-gray-700 block text-sm font-medium leading-5">Message</label>
        <ValidationProvider name="message" rules="required" v-slot="{ errors }">
          <div class="mt-1 relative rounded-md shadow-sm">
            <textarea v-model="message" id="message" rows="4"
                      :class="inputClasses(errors[0], 'form-textarea')"></textarea>
            <error-icon v-if="errors[0]"></error-icon>
          </div>
          <p class="mt-2 text-sm text-red-600" v-if="errors[0]">
            {{ errors[0] }}
          </p>
        </ValidationProvider>
      </div>
      <div>
          <span class="w-full inline-flex rounded-md shadow-sm">
            <button
                @click.prevent="handleSubmit(postForm)"
                type="submit"
                class="bg-wiq-green shadow border border-gray-50 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white transition ease-in-out duration-150">
              Send
              <spinner addtl-class="text-white ml-2" v-if="loading"></spinner>
            </button>
          </span>
      </div>
      <div class="text-sm text-red-600" v-if="errorMessage">
        {{ errorMessage }}
      </div>
    </div>
    <recaptcha
        ref="recaptcha"
        :captcha-action="captchaAction"
        :captcha-site-key="captchaSiteKey"
        :captcha-v3-site-key="captchaV3SiteKey"
        style="margin-bottom: 16px; margin-top: 16px;"
    ></recaptcha>
  </ValidationObserver>
</template>
<script>
import ErrorIcon from './icons/error-icon.vue';
import { recaptchaMix } from '../shared/recaptcha_mix.js';
import Recaptcha from '../shared/recaptcha.vue';
import Spinner from './icons/spinner.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default {
  name: 'contact-form',
  mixins: [recaptchaMix],
  components: {
    Spinner,
    ErrorIcon,
    ValidationProvider,
    ValidationObserver,
    Recaptcha,
  },
  data() {
    return {
      name: '',
      email: '',
      message: '',

      loading: false,
      sent: false,
      errorMessage: '',
    };
  },
  methods: {
    inputClasses(error, formClass) {
      const normalClasses = `${formClass} py-3 px-4 block w-full transition ease-in-out duration-150 border-gray-300 rounded-md focus:ring-wiq-green focus:border-wiq-green`;
      if (!error) {
        return normalClasses;
      }

      return `border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red ${normalClasses}`;
    },
    async postForm() {
      const vm = this;

      if (vm.loading === true) {
        return;
      }

      vm.loading = true;
      vm.errorMessage = '';
      const params = {
        'g-recaptcha-response-data': await this.$refs.recaptcha.getCaptchaResponse(),
        inquiry: {
          name: vm.name,
          email: vm.email,
          message: vm.message,
        },
      };
      const url = '/wiq_inquiry';
      axios.post(url, params)
          .then((response) => {
            vm.sent = true;
            vm.loading = false;
          })
          .catch((error) => {
            vm.loading = false;
            vm.errorMessage = `Error: ${error.toString()}`;
            if (vm.isRecaptchaError(error)) {
              vm.$refs.recaptcha.handleCaptchaFailed();
              vm.errorMessage = 'Recaptcha failed. Please solve and try again.';
            }
          });
    },
  },
};
</script>
