<template>
  <div
      class="not-prose mt-6 relative bg-white ring-wiq-green ring-offset-4 ring-2 shadow-lg rounded-lg py-16 px-4 sm:py-24 sm:px-6 lg:flex lg:items-center">
    <div class="absolute inset-x-0 top-0 transform translate-y-px">
      <div class="flex justify-center transform -translate-y-1/2">
                    <span
                        class="inline-flex rounded-full bg-wiq-green px-4 py-1 text-sm font-semibold tracking-wider uppercase text-green-50">
                      Middle School Pricing
                    </span>
      </div>
    </div>
    <div class="max-w-lg mx-auto w-full space-y-8">
      <div>
        <h2 class="sr-only">Price</h2>
        <p class="relative">
                  <span class="flex flex-col text-center">
                    <span class="text-5xl font-extrabold text-wiq-green tracking-tight">$300 /year</span>
                  </span>
        </p>
      </div>
      <a href="/activate?team=middle"
         class="no-underline text-center block leading-6 bg-wiq-green border border-gray-50 hover:text-gray-200 shadow text-white rounded-md w-full font-bold px-8 py-4 text-lg">
        Start a Free Trial
      </a>
      <ul role="list" class="rounded overflow-hidden grid gap-px sm:grid-cols-2">
        <li class="bg-gray-100 text-gray-500 py-4 px-4 flex items-center space-x-3 text-base text-white sm:col-span-2">
          <!-- Heroicon name: outline/check -->
          <svg class="h-6 w-6 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
               stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
          </svg>
          <span>Unlimited wrestler, parent, and coach accounts</span>
        </li>
        <li class="bg-gray-100 text-gray-500 py-4 px-4 flex items-center space-x-3 text-base text-white sm:col-span-2">
          <!-- Heroicon name: outline/check -->
          <svg class="h-6 w-6 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
               stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
          </svg>
          <span>Unlimited rosters</span>
        </li>
        <li class="bg-gray-100 text-gray-500 py-4 px-4 flex items-center space-x-3 text-base text-white sm:col-span-2">
          <svg class="h-6 w-6 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
               stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"/>
          </svg>
          <span>Free set up consultation (optional)</span>
        </li>
        <li class="bg-gray-100 text-gray-500 py-4 px-4 flex items-center space-x-3 text-base text-white sm:col-span-2">
          <!-- Heroicon name: outline/check -->
          <svg class="h-6 w-6 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
               stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
          </svg>
          <span>1,000 match videos</span>
        </li>
        <li class="bg-gray-100 text-gray-500 py-4 px-4 flex items-center space-x-3 text-base text-white sm:col-span-2">
          <!-- Heroicon name: outline/check -->
          <svg class="h-6 w-6 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
               stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
          </svg>
          <span>+$49 per 200 additional match videos</span>
        </li>
        <li class="bg-gray-100 text-gray-500 py-4 px-4 flex items-center space-x-3 text-base text-white sm:col-span-2">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-500" fill="none" viewBox="0 0 24 24"
               stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"/>
          </svg>
          <span>Support a business by wrestlers, for wrestlers</span>
        </li>
      </ul>
      <div class="mt-8 lg:mt-12 rounded-sm bg-pink-600 p-4">
        <h3 class="text-xl font-bold text-pink-50">New program?</h3>
        <p class="mt-2 text-base text-pink-50">
          If your wrestling program started in the last two years, you are eligible for 50% off your first two years of
          WrestlingIQ.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'middleschool-pricing'
};
</script>
