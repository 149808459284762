<template>
  <div class="grid grid-cols-1 lg:grid-cols-2 gap-8">
    <div>
      <div class="sm:hidden">
        <label for="tabs" class="sr-only">Select a tab</label>
        <select id="tabs" v-model="selectedTab" name="tabs" class="block w-full focus:ring-wiq-green focus:border-wiq-green border-gray-300 rounded-md">
          <option value="club">Club</option>
          <option value="hs">High School</option>
          <option value="college">College & RTCs</option>
        </select>
      </div>
      <div class="hidden sm:block">
        <div class="border-b border-gray-200">
          <nav class="-mb-px flex" aria-label="Tabs">
            <a @click.prevent="selectedTab = 'club'" :class="clubSelected ? activeClasses() : defaultClasses()"
               :aria-current="clubSelected ? 'page' : false" aria-controls="club-tab">
              Club </a>
            <a @click.prevent="selectedTab = 'hs'" :class="hsSelected ? activeClasses() : defaultClasses()"
               :aria-current="hsSelected ? 'page' : false" aria-controls="hs-tab">
              High School </a>
            <a @click.prevent="selectedTab = 'college'" :class="collegeSelected ? activeClasses() : defaultClasses()"
               :aria-current="collegeSelected ? 'page' : false" aria-controls="college-tab">
              College & RTC </a>
          </nav>
        </div>
        <div id="club-tab" :tabindex="clubSelected ? '0' : '-1'" v-if="clubSelected">
          <h2 class="mt-8 text-base sm:text-lg font-medium text-gray-700 lg:mt-12 leading-6">
            WrestlingIQ helps club teams compete with other sports and activities through effective, affordable technology built for wrestling.
          </h2>
          <a href="/club" class="underline text-wiq-green flex items-center mt-4">
            Learn more about WrestlingIQ for club teams
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="flex-shrink-0 h-5 w-5 text-wiq-green ml-2" fill="currentColor">
              <path d="M319.91 292H24a24 24 0 0 1-24-24v-24a24 24 0 0 1 24-24h295.91l35.66 36z" class="fa-secondary"/>
              <path d="M305.44 103.05L441 238.54l.06.06a25.23 25.23 0 0 1 0 34.84l-.06.06L305.44 409a24 24 0 0 1-33.94 0l-17-17a24 24 0 0 1 0-33.94L355.57 256 254.5 154a24 24 0 0 1 0-33.94l17-17a24 24 0 0 1 33.94-.01z" class="fa-primary"/>
            </svg>
          </a>
        </div>
        <div id="hs-tab" :tabindex="hsSelected ? '0' : '-1'" v-if="hsSelected">
          <h2 class="mt-8 text-base sm:text-lg font-medium text-gray-700 lg:mt-12 leading-6">
            WrestlingIQ helps high schools by providing an all-in-one toolkit to streamline your communication and team management.
          </h2>
          <a href="/high-school" class="underline text-wiq-green flex items-center mt-4">
            Learn more about WrestlingIQ for high school teams
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="flex-shrink-0 h-5 w-5 text-wiq-green ml-2" fill="currentColor">
              <path d="M319.91 292H24a24 24 0 0 1-24-24v-24a24 24 0 0 1 24-24h295.91l35.66 36z" class="fa-secondary"/>
              <path d="M305.44 103.05L441 238.54l.06.06a25.23 25.23 0 0 1 0 34.84l-.06.06L305.44 409a24 24 0 0 1-33.94 0l-17-17a24 24 0 0 1 0-33.94L355.57 256 254.5 154a24 24 0 0 1 0-33.94l17-17a24 24 0 0 1 33.94-.01z" class="fa-primary"/>
            </svg>
          </a>
        </div>
        <div id="college-tab" :tabindex="collegeSelected ? '0' : '-1'" v-if="collegeSelected">
          <h2 class="mt-8 text-base sm:text-lg font-medium text-gray-700 lg:mt-12 leading-6">
            WrestlingIQ helps RTCs provide engaging opportunities for their supporters and easy to use fundraising, registration, and online donation tools. College teams use WrestlingIQ for video & stats, team messaging, practice planning, and more.
          </h2>
          <a href="/college" class="underline text-wiq-green flex items-center mt-4">
            Learn more about WrestlingIQ for college and RTCs
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="flex-shrink-0 h-5 w-5 text-wiq-green ml-2" fill="currentColor">
              <path d="M319.91 292H24a24 24 0 0 1-24-24v-24a24 24 0 0 1 24-24h295.91l35.66 36z" class="fa-secondary"/>
              <path d="M305.44 103.05L441 238.54l.06.06a25.23 25.23 0 0 1 0 34.84l-.06.06L305.44 409a24 24 0 0 1-33.94 0l-17-17a24 24 0 0 1 0-33.94L355.57 256 254.5 154a24 24 0 0 1 0-33.94l17-17a24 24 0 0 1 33.94-.01z" class="fa-primary"/>
            </svg>
          </a>
        </div>
      </div>
    </div>
    <div class="relative" v-if="clubSelected">
      <blockquote class="mt-6 md:flex-grow md:flex md:flex-col bg-twk-black p-8 rounded-lg">
        <div class="relative text-lg font-medium text-white md:flex-grow">
          <svg class="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 opacity-20" fill="#ffd703" viewBox="0 0 32 32" aria-hidden="true">
            <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"/>
          </svg>
          <p class="relative">With WIQ you will grow your wrestling team with a focus on coaching and spending less time on admin work.</p>
        </div>
        <footer class="mt-8">
          <div class="text-base font-medium text-white">Jeff Marsh</div>
          <div class="text-base font-medium text-gray-200">Founder, The Mat Club</div>
          <div class="text-base font-medium text-gray-200">Former Executive Director @ Beat the Streets National</div>
        </footer>
      </blockquote>
      <div class="absolute -right-3 -bottom-3 lg:-right-8 lg:-bottom-8 rounded-full bg-white p-2 shadow-lg">
        <img alt="The Wrestling Coach"
             src="https://mktg-cdn.wrestlingiq.com/logos/customers/jeff_200x200.png"
             class="h-16 lg:h-16 w-auto">
      </div>
    </div>
    <div class="sm:hidden" v-if="clubSelected">
      <h2 class="mt-6 text-lg font-medium text-gray-700 sm:text-xl lg:mt-12 leading-6">
        WrestlingIQ helps club teams compete with other sports and activities through effective, affordable, technology built for wrestling.
      </h2>
      <a href="/club" class="underline text-wiq-green flex items-center mt-4">
        Learn more about WrestlingIQ for club teams
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="flex-shrink-0 h-5 w-5 text-wiq-green ml-2" fill="currentColor">
          <path d="M319.91 292H24a24 24 0 0 1-24-24v-24a24 24 0 0 1 24-24h295.91l35.66 36z" class="fa-secondary"/>
          <path d="M305.44 103.05L441 238.54l.06.06a25.23 25.23 0 0 1 0 34.84l-.06.06L305.44 409a24 24 0 0 1-33.94 0l-17-17a24 24 0 0 1 0-33.94L355.57 256 254.5 154a24 24 0 0 1 0-33.94l17-17a24 24 0 0 1 33.94-.01z" class="fa-primary"/>
        </svg>
      </a>
    </div>
    <div class="relative" v-if="hsSelected">
      <blockquote class="mt-6 md:flex-grow md:flex md:flex-col bg-blue-700 p-8 rounded-lg">
        <div class="relative text-lg font-medium text-white md:flex-grow">
          <svg class="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-red-600 opacity-50" fill="currentColor" viewBox="0 0 32 32" aria-hidden="true">
            <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"/>
          </svg>
          <p class="relative">
            As a one-stop shop for our practice and competition calendars, videos, messaging, fundraisers, booster club payments, and more, WrestlingIQ has proven to be extremely valuable in keeping the 200+ wrestlers, parents, and coaches in our program aligned and moving forward in the same direction.
          </p>
        </div>
        <footer class="mt-8">
          <div class="text-base font-medium text-white">Ray Blake</div>
          <div class="text-base font-medium text-blue-50">Head Coach, Bixby HS</div>
        </footer>
      </blockquote>
      <div class="absolute -right-3 -bottom-3 lg:-right-8 lg:-bottom-8 rounded-full bg-white p-2 shadow-lg">
        <img alt="Bixby HS logo"
             src="https://mktg-cdn.wrestlingiq.com/logos/customers/bixby_200x200.png"
             class="h-16 lg:h-16 w-auto">
      </div>
    </div>
    <div class="sm:hidden" v-if="hsSelected">
      <h2 class="mt-6 text-lg font-medium text-gray-700 sm:text-xl lg:mt-12 leading-6">
        WrestlingIQ helps high schools by providing an all-in-one toolkit. From video & stats to practice planning to parent accounts and team messaging, WrestlingIQ streamlines your team management.
      </h2>
      <a href="/high-school" class="underline text-wiq-green flex items-center mt-4">
        Learn more about WrestlingIQ for high school teams
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="flex-shrink-0 h-5 w-5 text-wiq-green ml-2" fill="currentColor">
          <path d="M319.91 292H24a24 24 0 0 1-24-24v-24a24 24 0 0 1 24-24h295.91l35.66 36z" class="fa-secondary"/>
          <path d="M305.44 103.05L441 238.54l.06.06a25.23 25.23 0 0 1 0 34.84l-.06.06L305.44 409a24 24 0 0 1-33.94 0l-17-17a24 24 0 0 1 0-33.94L355.57 256 254.5 154a24 24 0 0 1 0-33.94l17-17a24 24 0 0 1 33.94-.01z" class="fa-primary"/>
        </svg>
      </a>
    </div>
    <div class="relative" v-if="collegeSelected">
      <blockquote class="mt-6 md:flex-grow md:flex md:flex-col bg-dam-orange p-8 rounded-lg">
        <div class="relative text-lg font-medium text-white md:flex-grow">
          <svg class="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-black opacity-20" fill="currentColor" viewBox="0 0 32 32" aria-hidden="true">
            <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"/>
          </svg>
          <p class="relative">WrestlingIQ has been great for the Beaver Dam RTC for so many reasons. It makes our members easy to navigate and donors as well.</p>
        </div>
        <footer class="mt-8">
          <div class="text-base font-medium text-white">Nate Engel</div>
          <div class="text-base font-medium text-gray-200">The Beaver Dam RTC</div>
        </footer>
      </blockquote>
      <div class="absolute -right-3 -bottom-3 lg:-right-8 lg:-bottom-8 rounded-full bg-white p-2 shadow-lg">
        <img alt="The Dam RTC Logo"
             src="https://mktg-cdn.wrestlingiq.com/logos/customers/damrtc_200x200.png"
             class="h-16 lg:h-16 w-auto">
      </div>
    </div>
    <div class="sm:hidden" v-if="collegeSelected">
      <h2 class="mt-6 text-lg font-medium text-gray-700 sm:text-xl lg:mt-12 leading-6">
        WrestlingIQ helps RTCs provide engaging opportunities for their supporters, and easy to use fundraising, registration, and online donation tools. College teams use WrestlingIQ for video & stats, team messaging, practice planning, and more.
      </h2>
      <a href="/college" class="underline text-wiq-green flex items-center mt-4">
        Learn more about WrestlingIQ for college and RTCs
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="flex-shrink-0 h-5 w-5 text-wiq-green ml-2" fill="currentColor">
          <path d="M319.91 292H24a24 24 0 0 1-24-24v-24a24 24 0 0 1 24-24h295.91l35.66 36z" class="fa-secondary"/>
          <path d="M305.44 103.05L441 238.54l.06.06a25.23 25.23 0 0 1 0 34.84l-.06.06L305.44 409a24 24 0 0 1-33.94 0l-17-17a24 24 0 0 1 0-33.94L355.57 256 254.5 154a24 24 0 0 1 0-33.94l17-17a24 24 0 0 1 33.94-.01z" class="fa-primary"/>
        </svg>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'home-tabs',
  data() {
    return {
      selectedTab: 'club'
    }
  },
  computed: {
    clubSelected() {
      return this.selectedTab === 'club';
    },
    hsSelected() {
      return this.selectedTab === 'hs';
    },
    collegeSelected() {
      return this.selectedTab === 'college';
    }
  },
  methods: {
    activeClasses() {
      return "border-wiq-green text-base text-wiq-green w-1/3 py-4 px-1 text-center border-b-2 font-semibold cursor-pointer";
    },
    defaultClasses() {
      return "border-transparent text-base text-gray-500 hover:text-gray-700 hover:border-gray-300 w-1/3 py-4 px-1 text-center border-b-2 font-medium cursor-pointer";
    }
  }
};
</script>
<style scoped>
</style>
