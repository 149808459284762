<template>
  <div
      class="not-prose mt-6 relative bg-white ring-wiq-green ring-offset-4 ring-2 shadow-lg rounded-lg py-16 px-4 sm:py-24 sm:px-6 lg:flex lg:items-center">
    <div class="absolute inset-x-0 top-0 transform translate-y-px">
      <div class="flex justify-center transform -translate-y-1/2">
                    <span
                        class="inline-flex rounded-full bg-wiq-green px-4 py-1 text-sm font-semibold tracking-wider uppercase text-green-50">
                      High School Pricing
                    </span>
      </div>
    </div>
    <div class="max-w-lg mx-auto w-full space-y-8">
      <div class="grid grid-cols-1 gap-y-8 sm:gap-y-8 sm:grid-cols-2 sm:gap-x-8">
        <div class="flex flex-col gap-y-8">
          <div>
            <div class="text-sm text-base font-semibold uppercase tracking-wider">Annual cost</div>
            <div class="text-3xl mt-1">
              $600 <span class="text-gray-500 text-xl">/year</span>
            </div>
          </div>
          <div class="relative mt-auto sm:w-5/6">
            <a href="/activate?team=hs"
               class="block shadow px-4 py-4 border border-gray-50 text-xl text-center font-medium rounded-md text-white bg-wiq-green hover:text-gray-50 focus:outline-none focus:border-blue-200 focus:ring-blue-200 active:bg-gray-50 active:text-gray-50">
              Start free trial
            </a>
          </div>
        </div>
        <div>
          <div class="text-sm text-base font-semibold uppercase tracking-wider">Free trial</div>
          <p class="text-lg mt-4">
            Start a free trial anytime in the offseason (March to Nov). <span class="font-bold">No credit card required</span>.
          </p>
          <p class="text-lg mt-2">
            If you like WrestlingIQ, I will send you an invoice <span class="font-bold">two weeks after your first official practice</span>.
          </p>
        </div>
        <div class="sm:col-span-2">
          <div class="rounded-sm bg-pink-600 p-4"><h3 class="text-xl font-bold text-pink-50">New program?</h3> <p class="mt-2 text-base text-pink-50">
            If your wrestling program started in the last two years, you are eligible for 50% off your first two years of
            WrestlingIQ.
          </p></div>
        </div>
      </div>
      <div class="mt-12 mb-8">
        <div class="mx-auto h-0.5 w-full bg-gray-100 rounded-sm"></div>
        <ul role="list" class="rounded overflow-hidden grid gap-px sm:grid-cols-2">
          <li class="bg-gray-100 text-gray-500 py-4 px-4 flex items-center space-x-3 text-base text-white sm:col-span-2">
            <!-- Heroicon name: outline/check -->
            <svg class="h-6 w-6 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
            </svg>
            <span>Unlimited wrestler, parent, and coach accounts</span>
          </li>
          <li class="bg-gray-100 text-gray-500 py-4 px-4 flex items-center space-x-3 text-base text-white sm:col-span-2">
            <!-- Heroicon name: outline/check -->
            <svg class="h-6 w-6 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
            </svg>
            <span>Unlimited rosters</span>
          </li>
          <li class="bg-gray-100 text-gray-500 py-4 px-4 flex items-center space-x-3 text-base text-white sm:col-span-2">
            <svg class="h-6 w-6 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"/>
            </svg>
            <span>Free set up consultation (optional)</span>
          </li>
          <li class="bg-gray-100 text-gray-500 py-4 px-4 flex items-center space-x-3 text-base text-white sm:col-span-2">
            <!-- Heroicon name: outline/check -->
            <svg class="h-6 w-6 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
            </svg>
            <span>1,000 match videos</span>
          </li>
          <li class="bg-gray-100 text-gray-500 py-4 px-4 flex items-center space-x-3 text-base text-white sm:col-span-2">
            <!-- Heroicon name: outline/check -->
            <svg class="h-6 w-6 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
            </svg>
            <span>+$49/year per 200 additional match videos</span>
          </li>
          <li class="bg-gray-100 text-gray-500 py-4 px-4 flex items-center space-x-3 text-base text-white sm:col-span-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-500" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"/>
            </svg>
            <span>Support a business by wrestlers, for wrestlers</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'highschool-pricing'
};
</script>
