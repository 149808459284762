export const recaptchaMix = {
  props: {
    captchaAction: {
      type: String,
      required: true,
    },
    captchaSiteKey: {
      type: String,
      required: true,
    },
    captchaV3SiteKey: {
      type: String,
      required: true,
    }
  },
  methods: {
    isRecaptchaError(error) {
      var errors = error?.response?.data?.errors;
      if (errors && Array.isArray(errors)) {
        return errors.includes('recaptcha');
      }

      return false;
    }
  }
};
