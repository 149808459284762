export const currencyMix = {
  methods: {
    formatAsCurrency(amountInCents, minFractionDigits = 2, maxFractionDigits = 2) {
      if (!amountInCents) {
        amountInCents = 0;
      }

      return (amountInCents / 100.00).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: minFractionDigits,
        maximumFractionDigits: maxFractionDigits,
      });
    },
  },
};
