import { extend } from 'vee-validate';
import { required, email, digits } from 'vee-validate/dist/rules';

export default function () {
  // No message specified.
  extend('email', {
    ...email,
    message: 'Please enter your {_field_}.',
  });

  // Override the default message.
  extend('required', {
    ...required,
    message: 'Please enter your {_field_}.',
  });

  extend('digits', {
    ...digits,
    message: 'Please enter your 10 digit {_field_}',
  });
}
