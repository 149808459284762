<template>
  <div>
    <!-- Heroicon name: solid/minus -->
    <svg :class="svgClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
      <path fill-rule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"/>
    </svg>
    <span class="sr-only">{{ srOnly }}</span>
  </div>
</template>
<script>
export default {
  name: 'minus',
  props: {
    svgClass: {
      type: String,
      default: 'h-5 w-5 text-gray-400'
    },
    srOnly: {
      type: String,
      default: 'No'
    }
  }
};
</script>
