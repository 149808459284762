<template>
  <div class="pt-4 pb-24 overflow-hidden lg:relative">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit, failed } ">
      <div class="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <div>
          <div class="flex justify-between">
            <slot name="header"></slot>
            <!-- This example requires Tailwind CSS v2.0+ -->
            <nav class="max-w-sm" aria-label="Progress">
              <ol role="list" class="border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0">
                <li class="relative md:flex-1 md:flex">
                  <!-- Current Step -->
                  <div class="px-4 py-2 flex items-center text-xs font-medium" aria-current="step">
                    <span
                        class="flex-shrink-0 w-4 h-4 flex items-center justify-center border-2 border-wiq-green rounded-full">
                      <span class="text-wiq-green">1</span>
                    </span>
                    <span class="ml-2 text-xs font-medium text-wiq-green">Activate</span>
                  </div>
                  <!-- Arrow separator for lg screens and up -->
                  <div class="hidden md:block absolute top-0 right-0 h-full w-2" aria-hidden="true">
                    <svg class="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
                      <path d="M0 -2L20 40L0 82" vector-effect="non-scaling-stroke" stroke="currentcolor"
                            stroke-linejoin="round"/>
                    </svg>
                  </div>
                </li>
                <li class="relative md:flex-1 md:flex">
                  <!-- Upcoming Step -->
                  <div class="group flex items-center">
          <span class="px-4 py-2 flex items-center text-xs font-medium">
            <span
                class="flex-shrink-0 w-4 h-4 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400">
              <span class="text-gray-500 group-hover:text-gray-900">2</span>
            </span>
            <span class="ml-2 text-xs font-medium text-gray-500">Configure</span>
          </span>
                  </div>
                  <div class="hidden md:block absolute top-0 right-0 h-full w-2" aria-hidden="true">
                    <svg class="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
                      <path d="M0 -2L20 40L0 82" vector-effect="non-scaling-stroke" stroke="currentcolor"
                            stroke-linejoin="round"/>
                    </svg>
                  </div>
                </li>
                <li class="relative md:flex-1 md:flex">
                  <!-- Upcoming Step -->
                  <div class="group flex items-center">
          <span class="px-4 py-2 flex items-center text-xs font-medium">
            <span
                class="flex-shrink-0 w-4 h-4 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400">
              <span class="text-gray-500 group-hover:text-gray-900">3</span>
            </span>
            <span class="ml-2 text-xs font-medium text-gray-500">Go live</span>
          </span>
                  </div>
                </li>
              </ol>
            </nav>
          </div>
          <div class="mt-9 md:mt-20">
            <div v-if="isWlag">
              <div class="inline-flex space-x-4">
                <span class="rounded bg-pink-500 px-2.5 py-1 text-xs font-semibold text-white tracking-wide uppercase">
                  10% of sales go to Wrestle Like A Girl
                </span>
              </div>
            </div>
            <div class="mt-6 sm:max-w-xl">
              <h1 class="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                Create your team's WrestlingIQ account
              </h1>
              <div class="relative mt-6" v-if="!isRTC && showActivationForm">
                <p class="text-xl text-gray-500">
                  You won't pay a dime until your season starts or you collect your first payment.<br><br>
                </p>
              </div>
            </div>
            <div class="sm:max-w-xl mt-12" v-if="showParentBlockerChoices">
                <h2 class="text-base font-semibold uppercase text-gray-700 tracking-wider">
                    What is your role on the team?
                </h2>
                <div class="grid grid-cols-1 sm:grid-cols-2 gap-x-12 gap-y-3 mt-4">
                    <div @click.prevent="enbaleActivateForm" class="bg-gray-50 border shadow-xs border-gray-200 rounded-sm cursor-pointer p-8 flex items-center justify-center">
                        <h3 class="text-lg">I am a <span class="font-bold">coach</span> or <span class="font-bold">team admin</span></h3>
                    </div>
                    <div @click.prevent="enableParentBlocker" class="bg-gray-50 border shadow-xs border-gray-200 rounded-sm cursor-pointer p-8 flex items-center justify-center">
                        <h3 class="text-lg">I am a <span class="font-bold">parent</span> or <span class="font-bold">wrestler</span></h3>
                    </div>
                </div>
            </div>
            <div class="sm:max-w-xl mt-12" v-if="showParentBlocker">
                <div class="rounded-md bg-yellow-50 p-4 sm:max-w-xl">
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-yellow-400 mt-1">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                            </svg>
                        </div>
                        <div class="ml-3">
                            <h3 class="text-lg font-extrabold text-yellow-900">
                                This page is only for coaches and team admins
                            </h3>
                            <p class="text-base mt-6">
                                <span class="font-semibold">If you do not have a login</span>, please contact your coach for an <span class="font-semibold">invite</span> or <span class="font-semibold">team registration url</span> to join your team's WrestlingIQ account.
                            </p>
                            <p class="text-base mt-4">
                                <span class="font-semibold">If you already have a WrestlingIQ login</span>, sign in or reset your password.
                            </p>
                            <ul class="text-base mt-2">
                                <li><a href="/users/sign_in" class="underline">Sign in</a></li>
                                <li><a href="/users/password/new" class="underline">Reset your password</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <form v-show="showActivationForm" class="mt-8 grid grid-cols-1 gap-y-6 md:max-w-xl md:grid-cols-2 md:gap-x-8">
          <div class="md:col-span-2">
            <h2 class="text-lg font-medium text-gray-900">Your contact info</h2>
            <p class="text-sm text-gray-500 mt-1">As the point of contact, you will receive an admin/coach account and
              will be in charge of investing some time to configure WrestlingIQ.</p>
          </div>
          <ValidationProvider name="first name" rules="required" v-slot="{ errors }">
            <div>
              <label for="first-name" class="block text-sm font-medium text-gray-700">First name</label>
              <div class="mt-1 relative rounded-md shadow-sm">
                <input type="text" name="first-name" id="first-name"
                       autocomplete="given-name"
                       v-model="firstName"
                       :class="inputClasses(errors[0])"
                >
                <error-icon v-if="errors[0]"></error-icon>
              </div>
              <p class="mt-2 text-sm text-red-600" v-if="errors[0]">
                {{ errors[0] }}
              </p>
            </div>
          </ValidationProvider>
          <ValidationProvider name="last name" rules="required" v-slot="{ errors }">
            <div>
              <label for="last-name" class="block text-sm font-medium text-gray-700">Last name</label>
              <div class="mt-1 relative rounded-md shadow-sm">
                <input type="text" name="last-name" id="last-name"
                       autocomplete="family-name"
                       v-model="lastName"
                       :class="inputClasses(errors[0])"
                >
                <error-icon v-if="errors[0]"></error-icon>
              </div>
              <p class="mt-2 text-sm text-red-600" v-if="errors[0]">
                {{ errors[0] }}
              </p>
            </div>
          </ValidationProvider>
          <div class="md:col-span-2">
            <ValidationProvider name="email" rules="required|email" :debounce="500" v-slot="{ errors }">
              <div>
                <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <input type="text"
                         autocomplete="email"
                         name="email"
                         id="email"
                         v-model="email"
                         :class="inputClasses(errors[0])"
                  >
                  <error-icon v-if="errors[0]"></error-icon>
                </div>
                <p class="mt-2 text-sm text-red-600" v-if="errors[0]">
                  {{ errors[0] }}
                </p>
              </div>
            </ValidationProvider>
          </div>
          <div class="md:col-span-2">
            <ValidationProvider name="phone" rules="required|digits:10" v-slot="{ errors }">
              <div>
                <label for="phone" class="block text-sm font-medium text-gray-700">Phone</label>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <the-mask mask="(###) ### - ####"
                            v-model="phone"
                            :class="inputClasses(errors[0])"
                            id="phone"
                            ref="phone"
                            type="tel">
                  </the-mask>
                  <error-icon v-if="errors[0]"></error-icon>
                </div>
                <p class="mt-2 text-sm text-red-600" v-if="errors[0]">
                  {{ errors[0] }}
                </p>
              </div>
            </ValidationProvider>
          </div>
          <div class="md:col-span-2 mt-6">
            <h2 class="text-lg font-medium text-gray-900">Team info</h2>
            <p class="text-sm text-gray-500 mt-1">
              Please send in the team name as you want it to appear to your wrestlers/parents during registration and usage.
              <span v-if="isHsAndBooster"><br/><br/><strong>Note:</strong> please use the high school info instead of the booster club info for this section.</span>
            </p>
          </div>
          <div class="md:col-span-2">
            <ValidationProvider name="team name" rules="required" v-slot="{ errors }">
              <div>
                <label for="first-name" class="block text-sm font-medium text-gray-700">Team name</label>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <input type="text" name="team-name" id="team-name"
                         v-model="teamName"
                         autocomplete="organization"
                         :class="inputClasses(errors[0])"
                  >
                  <error-icon v-if="errors[0]"></error-icon>
                </div>
                <p class="mt-2 text-sm text-red-600" v-if="errors[0]">
                  {{ errors[0] }}
                </p>
              </div>
            </ValidationProvider>
          </div>
          <div class="md:col-span-2">
            <ValidationProvider name="time zone" rules="required" v-slot="{ errors }">
              <div>
                <label for="tz" class="block text-sm font-medium text-gray-700">Time zone</label>
                <div class="mt-1">
                  <select id="tz" v-model="timeZone" name="time zone"
                          class="block w-full bg-white border border-gray-300 rounded-md shadow-sm py-3 px-4 focus:outline-none focus:ring-wiq-green focus:border-wiq-green">
                    <option v-for="tz in timeZones" :value="tz[0]">{{ tz[1] }}</option>
                  </select>
                </div>
                <p class="mt-2 text-sm text-red-600" v-if="errors[0]">
                  {{ errors[0] }}
                </p>
              </div>
            </ValidationProvider>
          </div>
          <div class="md:col-span-2">
            <ValidationProvider
              name="start date"
              :rules="{ required: true, dateRange: { min: minStartDate, max: maxStartDate } }"
              v-slot="{ errors }"
            >
              <div>
                <label for="startdate" class="block text-sm font-medium text-gray-700">
                  <template v-if="isHsCollegeOrJhs">
                    Day of first practice
                  </template>
                  <template v-else-if="isRTC">
                    Goal date to switch systems?
                  </template>
                  <template v-else>
                    Goal date to accept your first payment/registration through WrestlingIQ
                  </template>
                </label>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <input
                    type="date"
                    id="startDate"
                    name="startDate"
                    v-model="startDate"
                    @input="$refs.observer.validate()"
                    :min="minStartDate"
                    :max="maxStartDate"
                    :class="inputClasses(errors[0])"
                  />
                  <error-icon position-classes="absolute inset-y-0 right-6 pr-6" v-if="errors[0]"></error-icon>
                </div>
                <p class="mt-2 text-sm text-red-600" v-if="errors[0]">
                  {{ errors[0] }}
                </p>
              </div>
            </ValidationProvider>
          </div>
          <div v-if="isClub" class="md:col-span-2">
            <div class="flex justify-between gap-x-4">
              <p class="block text-sm font-medium text-gray-700">
                Is the club a 501c3, or other non profit, entity?
              </p>
              <button @click="isNonProfit = !isNonProfit" type="button"
                      :class="isNonProfit ? 'bg-wiq-green' : 'bg-gray-200'"
                      class="self-center relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-wiq-green"
                      role="switch"
                      :aria-checked="isNonProfit">
                <span class="sr-only">Is the club a 501c3, or other non profit, entity?</span>
                <span aria-hidden="true" :class="isNonProfit ? 'translate-x-5' : 'translate-x-0'"
                      class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow ring-0 transition ease-in-out duration-200">
                </span>
              </button>
            </div>
            <div class="flex justify-between gap-x-4 mt-8">
              <p class="block text-sm font-medium text-gray-700">
                Do you want info about a custom website with WrestlingIQ?<br/>
                <span class="text-gray-500">
                  Websites are an optional, paid, service.
                Examples include <a href="https://www.thedamrtc.org/" target="_blank"
                                    class="underline text-dam-orange">The Dam RTC</a>,
                <a href="https://www.westpointwrestlingclub.org/" target="_blank" class="underline text-wp-gray">Westpoint
                  Wrestling Club</a>,
                <a href="https://www.yalestreetwc.com/" target="_blank" class="underline text-ys-red"> and Yale St.</a>
                </span>
              </p>
              <button @click="websiteInfo = !websiteInfo" type="button"
                      :class="websiteInfo ? 'bg-wiq-green' : 'bg-gray-200'"
                      class="self-center relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-wiq-green"
                      role="switch"
                      :aria-checked="websiteInfo">
                <span class="sr-only">Send information about a website hosted through WrestlingIQ?</span>
                <span aria-hidden="true" :class="websiteInfo ? 'translate-x-5' : 'translate-x-0'"
                      class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow ring-0 transition ease-in-out duration-200">
                  </span>
              </button>
            </div>
          </div>
          <div class="md:col-span-2">
            <label for="hearAbout" class="block text-sm font-medium text-gray-700">
              How did you hear about WrestlingIQ?
            </label>
            <div class="mt-1 relative rounded-md shadow-sm">
              <input type="text"
                     name="hearAbout"
                     id="hearAbout"
                     v-model="hearAbout"
                     class="py-3 px-4 shadow-sm block w-full border-gray-300 rounded-md sm:text-sm">
            </div>
          </div>
        </form>
        <recaptcha
            ref="recaptcha"
            :captcha-action="captchaAction"
            :captcha-site-key="captchaSiteKey"
            :captcha-v3-site-key="captchaV3SiteKey"
            style="margin-bottom: 16px; margin-top: 16px;"
        ></recaptcha>
      </div>
      <div class="sm:mx-auto sm:max-w-3xl sm:px-6">
        <div class="py-12 sm:relative sm:mt-4 sm:py-16 lg:fixed lg:inset-y-0 lg:right-0 lg:w-1/2">
          <div v-show="showActivationForm" class="relative px-4 sm:mx-auto sm:max-w-2xl sm:px-0 lg:max-w-lg lg:h-full lg:pl-12">
            <section aria-labelledby="summary-heading"
                     class="mt-16 bg-gray-50 border border-gray-200 shadow-sm rounded-lg px-4 py-6 sm:p-6 lg:p-8">
              <h2 id="summary-heading" class="text-lg font-medium text-gray-900">This WrestlingIQ account is for..</h2>
              <div class="mt-4">
                <label for="teamLevel" class="block text-sm font-medium text-gray-700 hidden">Account type</label>
                <select id="teamLevel" v-model="teamLevel" name="team level"
                        class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-wiq-green focus:border-wiq-green sm:text-sm">
                  <option v-for="l in availableLevels" :value="l">{{ l }}</option>
                </select>
              </div>
              <p class="text-sm uppercase font-semi tracking-widest mt-8 text-gray-700">pricing</p>
              <div v-if="isClub" class="mt-4">
                <p class="text-4xl font-extrabold text-gray-900">
                  2% transaction fee
                </p>
                <p class="text-gray-700 text-baseline mt-2">
                  The 2% transaction fee is added to each payment and paid by the registrant. All you pay are card processing fees
                  of 2.9% + 30 cents.
                </p>
              </div>
              <div v-if="isBooster" class="mt-4">
                <p class="text-4xl font-extrabold text-gray-900">
                  3% transaction fee
                </p>
                <p class="text-gray-700 text-baseline mt-2">
                  WrestlingIQ adds a 3% fee to each donation [or contribution] collected. Donors usually opt-in to cover the fee. <span class="font-medium">Card processing fees of 2.9% + 30 cents are separate from WrestlingIQ fees.</span>
                </p>
              </div>
              <div v-if="isHsAndBooster" class="mt-4">
                <p class="text-4xl font-extrabold text-gray-900">
                  {{ hsPrice }} (HS)
                </p>
                <p class="text-gray-700 text-baseline mt-2">
                  High school price invoiced two weeks after your first practice.<br/><br/>
                </p>
                <p class="text-4xl font-extrabold text-gray-900 mt-4">
                  3% transaction fee (Booster)
                </p>
                <p class="text-gray-700 text-baseline mt-4">
                  For Booster clubs, WrestlingIQ adds a 3% fee to each donation [or contribution] collected. Donors usually opt-in to cover the fee. <span class="font-medium">Card processing fees of 2.9% + 30 cents are separate from WrestlingIQ fees.</span>
                </p>
              </div>
              <div v-if="isHs || isCollege" class="mt-4">
                <p class="text-4xl font-extrabold text-gray-900">
                  {{ hsPrice }}
                </p>
                <p class="text-gray-700 text-baseline mt-2">
                  Invoiced two weeks after your first practice.
                </p>
              </div>
              <div v-if="isJhs || isRec" class="mt-4">
                <p class="text-4xl font-extrabold text-gray-900">
                  $300 /year
                </p>
                <p class="text-gray-700 text-baseline mt-2">
                  Invoiced two weeks after your first practice.
                </p>
                <p v-if="isRec" class="text-gray-700 text-baseline mt-2">
                  Our Town Recreation Program pricing is for town/city run teams that do not collect their own payments and instead receive a spreadsheet from their recreation department of registrants.
                </p>
                <p v-if="isRec" class="text-gray-700 text-baseline mt-2">
                  If you are a youth program who collects your own payments, please select 'Club' above for our package that offers online payment collection.
                </p>
              </div>
              <div v-if="isRTC" class="mt-4">
                <p class="text-4xl font-extrabold text-gray-900">
                  $1,600 one time fee
                </p>
                <p class="text-3xl font-extrabold text-gray-500 mt-2">
                  +
                </p>
                <p class="text-4xl font-extrabold text-gray-900 mt-2">
                  $1,000 /year
                </p>
                <p class="text-gray-700 text-baseline mt-6">
                  I will be in touch shortly after receiving your submission to talk through your goals and the custom website design process.
                </p>
              </div>
              <button type="submit"
                      @click.prevent="handleSubmit(createLead)"
                      :disabled="creatingLead"
                      class="mt-12 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-wiq-green hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600"
              >
                Get started
                <spinner v-if="creatingLead" class="ml-2"></spinner>
              </button>
              <p v-if="error" class="text-red-600 mt-2">
                {{ errorMessage }}
              </p>
              <small v-if="failed" class="text-red-600 mt-2">
                Please correct one of the errors on the form
              </small>
            </section>
          </div>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { TheMask } from 'vue-the-mask';
import ErrorIcon from './icons/error-icon.vue';
import { recaptchaMix } from '../shared/recaptcha_mix.js';
import Recaptcha from '../shared/recaptcha.vue';
import Spinner from './icons/spinner.vue';

extend('dateRange', {
  params: ['min', 'max'],
  validate(value, { min, max }) {
    if (!value) return true;

    // Strip time components and compare dates only
    const selectedDate = new Date(value).setHours(0,0,0,0);
    const minDate = new Date(min).setHours(0,0,0,0);
    const maxDate = new Date(max).setHours(0,0,0,0);

    return selectedDate >= minDate && selectedDate <= maxDate;
  },
  message(_, { min, max }) {
    const formatDate = (dateStr) => {
      // Create date object and set to midnight UTC to avoid timezone shifts
      const date = new Date(dateStr);
      date.setUTCHours(12, 0, 0, 0);

      return date.toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        timeZone: 'UTC'  // Ensure consistent timezone handling
      });
    };

    return `Please select a date between ${formatDate(min)} and ${formatDate(max)}`;
  }
});

export default {
  name: 'activate',
  mixins: [recaptchaMix],
  components: {
    Spinner,
    ErrorIcon,
    Recaptcha,
    TheMask,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    teamType: {
      type: String,
      default: null
    },
    referralCode: {
      type: String,
      default: null,
    },
    referralId: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      teamLevel: null,
      availableLevels: [
        'Booster club',
        'Club',
        'College',
        'High school',
        'High school and Booster club',
        'Middle school / Junior high',
        'RTC',
        'Town Rec Program'
      ],
      timeZone: null,
      timeZones: [['America/Los_Angeles', 'Pacific'], ['America/Denver', 'Mountain (most areas)'], ['America/Chicago', 'Central (most areas)'], ['America/New_York', 'Eastern (most areas)'], ['America/Detroit', 'Eastern - MI (most areas)'], ['America/Kentucky/Louisville', 'Eastern - KY (Louisville area)'], ['America/Kentucky/Monticello', 'Eastern - KY (Wayne)'], ['America/Indiana/Indianapolis', 'Eastern - IN (most areas)'], ['America/Indiana/Vincennes', 'Eastern - IN (Da, Du, K, Mn)'], ['America/Indiana/Winamac', 'Eastern - IN (Pulaski)'], ['America/Indiana/Marengo', 'Eastern - IN (Crawford)'], ['America/Indiana/Petersburg', 'Eastern - IN (Pike)'], ['America/Indiana/Vevay', 'Eastern - IN (Switzerland)'], ['America/Indiana/Tell_City', 'Central - IN (Perry)'], ['America/Indiana/Knox', 'Central - IN (Starke)'], ['America/Menominee', 'Central - MI (Wisconsin border)'], ['America/North_Dakota/Center', 'Central - ND (Oliver)'], ['America/North_Dakota/New_Salem', 'Central - ND (Morton rural)'], ['America/North_Dakota/Beulah', 'Central - ND (Mercer)'], ['America/Boise', 'Mountain - ID (south); OR (east)'], ['America/Phoenix', 'MST - Arizona (except Navajo)'], ['America/Anchorage', 'Alaska (most areas)'], ['America/Juneau', 'Alaska - Juneau area'], ['America/Sitka', 'Alaska - Sitka area'], ['America/Metlakatla', 'Alaska - Annette Island'], ['America/Yakutat', 'Alaska - Yakutat'], ['America/Nome', 'Alaska (west)'], ['America/Adak', 'Aleutian Islands'], ['Pacific/Honolulu', 'Hawaii']],
      websiteInfo: false,
      isNonProfit: false,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      teamName: '',
      startDate: '',
      creatingLead: false,
      hearAbout: '',

      showParentBlockerChoices: true,
      showParentBlocker: false,
      showActivationForm: false,

      errorMessage: '',
      error: false,
    };
  },
  mounted() {
    this.teamLevel = this.teamType;
  },
  computed: {
    hsPrice() {
      if (this.isWlag) {
        return '$540 /year';
      }

      return '$600 /year';
    },
    isWlag() {
      return this.referralCode === 'wlag';
    },
    isBooster() {
      return this.teamLevel === 'Booster club';
    },
    isClub() {
      return this.teamLevel === 'Club';
    },
    isCollege() {
      return this.teamLevel === 'College';
    },
    isHsCollegeOrJhs() {
      return ['College', 'High school', 'Middle school / Junior high', 'High school and Booster club', 'Town Rec Program'].includes(this.teamLevel);
    },
    isHs() {
      return this.teamLevel === 'High school';
    },
    isHsAndBooster() {
      return this.teamLevel === 'High school and Booster club';
    },
    isRTC() {
      return this.teamLevel === 'RTC';
    },
    isJhs() {
      return this.teamLevel === 'Middle school / Junior high';
    },
    isRec() {
      return this.teamLevel === 'Town Rec Program';
    },
    minStartDate() {
      // Get today's date in user's timezone
      const today = new Date()
      today.setHours(0,0,0,0)
      return today.toISOString().split('T')[0]
    },
    maxStartDate() {
      // 10 months from today
      const tenMonthsFromNow = new Date()
      tenMonthsFromNow.setMonth(tenMonthsFromNow.getMonth() + 10)
      return tenMonthsFromNow.toISOString().split('T')[0]
    }
  },
  methods: {
    enableParentBlocker() {
      this.showParentBlockerChoices = false
      this.showParentBlocker = true
      this.showActivationForm = false
    },
    enbaleActivateForm() {
      this.showParentBlockerChoices = false
      this.showParentBlocker = false
      this.showActivationForm = true
    },
    inputClasses(error) {
      const normalClasses = 'py-3 px-4 shadow-sm block w-full border-gray-300 rounded-md sm:text-sm';
      if (!error) {
        return `${normalClasses} focus:ring-wiq-green focus:border-wiq-green border-gray-300`;
      }

      return `border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red ${normalClasses}`;
    },
    async createLead() {
      if (this.creatingLead) {
        return;
      }

      this.creatingLead = true;
      this.errorMessage = '';
      this.error = false;

      const params = {
        'g-recaptcha-response-data': await this.$refs.recaptcha.getCaptchaResponse(),
        lead: {
          name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          phone_number: this.phone,
          team_name: this.teamName,
          level: this.teamLevel,
          time_zone: this.timeZone,
          preferred_slug: this.teamName.replace(/\s+/g, ''),
          is_non_profit: this.isNonProfit,
          website_interest: this.websiteInfo,
          season_start_at: this.startDate,
          referral_partner_id: this.referralId,
          hear_about_us: this.hearAbout,
          lead_type: 'free_trial',
        }
      };
      const vm = this;
      axios.post('/v2/leads', params)
          .then((response) => {
            this.creatingLead = false;
            window.location.assign(`/activate/config?email=${encodeURIComponent(this.email)}`);
          })
          .catch((error) => {
            vm.creatingLead = false;
            vm.creatingSession = false;
            vm.errorMessage = `Error, please try again later or contact support@wrestlingiq.com ${error.toString()}`;
            vm.error = true;
            if (vm.isRecaptchaError(error)) {
              vm.$refs.recaptcha.handleCaptchaFailed();
              vm.errorMessage = 'Recaptcha failed. Please solve and try again.';
            }
          });
    }
  }
};
</script>
