import * as Sentry from "@sentry/vue";

export const initSentry = (Vue) => {
  Sentry.init({
    Vue,
    dsn: "https://4b96b973744b4bf2ab5f5921513d01ca@o116424.ingest.us.sentry.io/256247",
    environment: `${process.env.WIQ_ENV}`,
    integrations: [
      Sentry.replayIntegration(),
    ],

    // Capture Replay for 0% of all sessions,
    // but 100% of sessions with an error
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  });

  return Sentry; // Return the Sentry instance
};
